import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import DetaFormSubmit from "./DetaFormSubmit";

export default function Contact() {
  const Site_Url = "https://namechangeserviceinbengaluru.co.in/";
  const BASE_URL = Site_Url + "admin/API/";

  const [slider, setbannerdata] = useState([]);
  useEffect(() => {
    const AllBanner = async () => {
      const response = await fetch(`${BASE_URL}bannerAPI.php`);
      const jsonData = await response.json();
      setbannerdata(jsonData);
    };
    AllBanner();
  }, [BASE_URL]);

  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  //Page Meta Tags
  const [PagemetaTags, setPagemetaTags] = useState([]);
  useEffect(() => {
    const MetaTagsData = async () => {
      const response = await fetch(`${BASE_URL}pagemetaTags.php`);
      const jsonData = await response.json();
      setPagemetaTags(jsonData);
    };
    MetaTagsData();
  }, [BASE_URL]);

  return (
    <>
      <Helmet>
        <title>{PagemetaTags.ContactMetatitle}</title>
        <meta name="keywords" content={PagemetaTags.ContactMetaKeyword} />
        <meta name="description" content={PagemetaTags.ContactMetaDesc} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      {/* Banner Start */}
      <section>
        <div className="banner">
          <img
            src={slider.contact_banner}
            alt={slider.contact_banner_alt}
            title={slider.contact_banner_title}
          />
        </div>
      </section>
      {/* Banner End */}

      <section className="contact_sc videoForm_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service_title text-center">
                <h2>Contact Us</h2>
                <p>
                  Wherever & whenever you need us. We are here for you – contact
                  us for all your support needs. be it technical, general
                  queries or information support.
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-4 col-md-12">
              {webSetting.iframe_formUrl && (
                <DetaFormSubmit
                  formurl={webSetting.iframe_formUrl}
                  title={webSetting.matatitle}
                />
              )}
            </div>

            <div className="col-lg-8 col-md-12">
              <div className="video_bx">
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${webSetting.iframe_video}`}
                  title="Father Name Change Consultant in India | Fatherame Change Agent in India"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen=""
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
